import React from 'react';
import Seo from '../components/Seo';
import MainLayout from '../layouts/MainLayout';
import RedStoneArchivesLogo from '../images/redstonearchives.gif';

const NotFoundPage = () => {
    return (
        <MainLayout>
            <Seo />
            <div className="flex h-screen">
                <div className="m-auto">
                    <img
                        className="w-full max-w-lg"
                        src={RedStoneArchivesLogo}
                        alt="coming soon"
                    />
                </div>
            </div>
        </MainLayout>
    );
};

export default NotFoundPage;
